var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login login-with-news-feed"},[_vm._m(0),_c('div',{staticClass:"login-container"},[_vm._m(1),_c('div',{staticClass:"login-content"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"fs-13px",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.checkForm)}}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-15px"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.username),expression:"user.username"}],staticClass:"form-control h-45px fs-13px",class:classes,attrs:{"autocomplete":"username","type":"text","placeholder":"User Name","id":"username","name":"username"},domProps:{"value":(_vm.user.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "username", $event.target.value)}}}),_c('label',{staticClass:"d-flex align-items-center fs-13px text-gray-600",attrs:{"for":"username"}},[_vm._v("User Name")]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-floating mb-15px"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control h-45px fs-13px",class:classes,attrs:{"autocomplete":"current-password","type":"password","placeholder":"Password","id":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}}),_c('label',{staticClass:"d-flex align-items-center fs-13px text-gray-600",attrs:{"for":"password"}},[_vm._v("Password")]),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"mb-15px"},[_c('button',{staticClass:"btn btn-success d-block h-45px w-100 btn-lg fs-14px",attrs:{"type":"submit","disabled":invalid}},[_vm._v("เข้าสู่ระบบ")])]),_c('hr',{staticClass:"bg-gray-600 opacity-2 mt-40px"}),_c('div',{staticClass:"text-gray-600 text-center text-gray-500-darker mb-0"},[_vm._v("© SRRU Monitoring All Right Reserved 2022")])],1)]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/bk_obj.jpeg)"}}),_c('div',{staticClass:"news-caption"},[_c('h4',{staticClass:"caption-title"},[_c('b',[_vm._v("Surin")]),_vm._v(" Election Report")]),_c('p',[_vm._v("ระบบประมวลผลเลือกตั้ง นายก อบจ.สุรินทร์ อย่างไม่เป็นทางการ โดยศูนย์ประสานงานเลือกตั้ง นายก อบจ.สุรินทร์")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-header mb-30px"},[_c('div',{staticClass:"brand"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"logo"}),_c('b',[_vm._v("Surin")]),_vm._v(" Election ")]),_c('small',[_vm._v("ระบบประมวลผลเลือกตั้ง นายก อบจ. สุรินทร์")])]),_c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-sign-in-alt"})])])}]

export { render, staticRenderFns }